<template>
  <f7-page class="profile-page">
    <template #fixed>
      <ProfileNavigationComponent type="back" :title="$t.getTranslation('LBL_ADDRESS')" :settings="false" />
    </template>

    <section class="profile-container">
      <div class="container">
        <div class="address-content-container">
          <template v-if="addressList && addressList.length > 0">
            <div v-for="(address, index) in addressList" :key="'ord_' + index" class="address-list" :class="{ default: address.IsDefault }" @click="editAddress(address)">
              <h3>{{ $h.getRevereFullName(address) }}</h3>
              <p>{{ address.PrimaryAddress }}, {{ address.District }}, {{ address.City }}, {{ address.Province }}</p>
              <p class="mobile">{{ $h.formatMobile(address) }}</p>

              <f7-link v-if="isCart" class="delete-address" @click.stop="deleteAddress(address)">
                <font-awesome-icon :icon="['far', 'trash']" fixed-width />
              </f7-link>

              <span v-if="address.IsDefault" class="defaultaddress">
                <font-awesome-icon :icon="['fas', 'check-circle']" fixed-width />
                {{ $t.getTranslation('LBL_DEFAULT_ADDRESS') }}
              </span>
            </div>
          </template>

          <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_ADDRESS_EMPTY')" />

          <f7-button fill large href="/address/settings/">{{ $t.getTranslation('LBL_ADD_ADDRESS') }}</f7-button>
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref, inject, defineAsyncComponent } from 'vue'

import { get, post } from '@/utils/axios'
import { useStore } from '@/store'
import { helpers } from '@/utils/helpers'

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProfileNavigationComponent.vue'))
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ '@/components/NoDataFoundComponent.vue'))

export default defineComponent({
  name: 'ProfileAddressPage',
  components: {
    ProfileNavigationComponent,
    NoDataFoundComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const isCart = ref(props.f7route?.query?.cart ? true : false)

    const addressList = ref([])

    const store = useStore()

    const UserKey = store.getters['user/getData']?.UserKey

    const getAddressList = async () => {
      let returnData = await get('/user/address/list', { UserKey: UserKey })
      addressList.value = returnData
    }

    const editAddress = (address) => {
      if (isCart.value) {
        props.f7router.navigate({ name: 'cartPage', query: { uakey: address.UserAddressKey } })
        return
      }
      props.f7router.navigate({ name: 'addressSettings', query: { key: address.UserAddressKey } })
    }

    const deleteAddress = (address) => {
      helpers.createConfirmation({
        message: $t.getTranslation('LBL_CONFIRM_DELETE_ADDRESS'),
        confirm: async () => {
          await post('/user/address/remove', { key: address.UserAddressKey })
          getAddressList()
        }
      })
    }

    onMounted(() => {
      getAddressList()
    })

    return {
      addressList,
      editAddress,
      deleteAddress,
      isCart
    }
  }
})
</script>

<style scoped>
.empty-container {
  margin-bottom: 30px;
}
</style>
